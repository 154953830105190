:root {
  --color-primary: #d2232a;
  --color-secondary: #d2232a;
  --color-tertiary: #eb9ba4 ;
  --color-quarternary: #d84e61 ; 
  --color-gold:#BF9663;
  --font-heading: 'Noto Serif', serif;
  --font-body: 'Avenir', Arial, Helvetica, sans-serif;
  --padding-page: 24px;
}

html, body {
    background-color: var(--color-secondary);
    height: 100vh;
    max-width: 100vw;
    font-family: var(--font-body);
    font-weight: bold;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    border: 0;
    box-sizing: border-box;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    color: white;
    font-size: 20px;
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

p,
a,
li,
blockquote,
input {
    font-family: var(--font-body);
}
